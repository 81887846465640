import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import QntSwitch from '../../microComponents/qntSwitch/index';
import Addcart from '../CartButton/index';
import Offcanvas from 'react-bootstrap/Offcanvas';

const Index = ({ item }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setItems([]);
    setItemTitle('');
    setShow(false);
  };
  const handleModal = (name) => {
    setShow(true);
    setItemTitle(name);
  };
  const [itemTitle, setItemTitle] = useState('');
  const [items, setItems] = useState([
    { itemName: '', itemPrice: 0, itemQty: 0 },
  ]);
  // useEffect()

  return (
    <div>
      <section className='ItemBox' onClick={(e) => handleModal(item.name)}>
        <div className='ItemBox-image'>
          <img src={`https://hms.tuna-erp.com/${item.image}`} alt='' />
        </div>
        <div className='ItemBox-desc'>
          <h1 className='ItemBox-desc-title'>{item.name}</h1>
          <p className='ItemBox-desc-desc'>{item.description} </p>
          <div className='ItemBox-desc-pricebox'>
            {item.items.length > 0 ? (
              item.items.map((i) => (
                <div className='itemPrice'>
                  <div className='itemPrice-name'>{i.item_name}</div>
                  <div className='itemPrice-price'>
                    <span className='itemPrice-price-rs'>Rs.</span>
                    <span className='itemPrice-price-amt'>{i.rate}</span>
                  </div>
                </div>
              ))
            ) : (
              <p>Nothing Available</p>
            )}
          </div>
        </div>
      </section>

      <Offcanvas show={show} onHide={handleClose} placement='bottom'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {' '}
            <div className='modTitle'>{itemTitle}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {item.items.length > 1 ? (
            item.items.map((i) => (
              <div className='adderWrapper' key={i.item_name}>
                <section className='adderItems'>
                  <div className='adderItems-name'>{i.item_name}</div>
                  <QntSwitch
                    item={{
                      product: i.item_code,
                      name: `${i.item_name}`,
                    }}
                  />
                </section>
              </div>
            ))
          ) : (
            <div className='adderWrapper'>
              <section className='adderItems'>
                <div className='adderItems-name'>{itemTitle}</div>
                <QntSwitch
                  item={{
                    product: item.items[0].item_code,
                    name: `${itemTitle}`,
                  }}
                />
              </section>
            </div>
          )}

          <div style={{ height: '2rem' }}></div>
          <Addcart />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Index;
